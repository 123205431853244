main{
  position: relative;
  top: 46px;
	background-color: #1B1F23;
	margin: 0 auto;
	max-width: 1000px;
	padding: 10px 12px 40px 12px;
  min-height: calc(100vh - 294px);
  flex-grow: 1;
}

#logo{
	width: 100%;
	max-height: 60px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

#logo-img{
	width: 173px;
	height: 60px;
}

#main-content{
  display: flex;
  justify-content: space-between;
}

#left-content{
  display: flex;
  flex-direction: column;
  width: 160px;
}

.right-content{
	display: flex;
  flex-direction: column;
  width: 145px;
}

#center-content{
  width: 477px;
}

#loot-bet-ad {
	height: 229px;
	margin-bottom: 8px;
}

#betaway-ad{
	margin-bottom: 8px;
}

h5{
  font-size: 10px;
  color: hsla(200,6%,60%,.65);
  margin-bottom: 6.7px;
}
h3{
  margin: 6px 0 6px;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  color: #929a9e;
}
#teams-box{
  background-color:rgba(45,56,68,.35);
}

#complete-ranking{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  background-color: #435971;
  color: #b9bdbf;
  margin-bottom: 8px;
}

#complete-ranking:hover{
  background-color: #59728E;
  cursor: pointer;
  color: #B0B6AE;
}

#events-box{
  background-color:rgba(45,56,68,.35);
  margin-bottom: 8px;
}

#today-matches{
  background-color: #2d3844;
}

#today-matches h4{
  font-size: 10px;
  color: white;
}

#today-matches-title{
  background-color: #435971;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#today-matches-title #star{
  color: #fff;
  font-size: 10px;
}

.ad{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 1px;
}

#streamers{
  background-color: #2d3844;
}

#new-topic{
  transition: background-color .2s cubic-bezier(.59,.43,.99,1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  background-color: #435971;
  height: 26px;
  color: #929a9e;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
}

#new-topic:hover{
  cursor: pointer;
  background-color: #59728E;
  color: #B0BDBC;
}

#ad4{
  margin-top: 10px;
}

#latest-replay{
  background-color: #2d3844;
}

#main-event{
  display: flex;
  height: 70px;
  background-color: #2e3843;
  margin-bottom: 8px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.5);
}

#main-event-logo{
  height: 100%;
  padding: 5px;
  background: radial-gradient(ellipse at center,hsla(0,0%,100%,.1) 0,hsla(0,0%,100%,0) 80%);
}

#main-event-logo img{
  width:60px;
}

#main-event-title{
  color: #fff;
  text-shadow: 0 1px 0 rgba(0,0,0,.5);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  top: 5px;
}

#main-event-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#main-event-links{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 2px;
}
.main-event-link{
  background-color: #435971;
  font-weight: 700;
  font-size: 14px;
  transition: .2s ease;
}

.main-event-link a{
  color: white;
  padding: 2px 14px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.5);
}

.main-event-link:hover{
  cursor: pointer;
  background-color: #59728E;
}

#main-news{
  max-width: 100%;
}

#main-news img{
  max-width: 100%;
  object-fit: cover;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.5);
}

#more-news{
  font-size: 14px;
  text-align: center;
  margin: 0;
  padding: 5px;
  color: #87a3bf;
  background-color: #2d3844;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.5);
  cursor: pointer;

  margin-top: 10px;
}

#more-news:hover{
  transition: .2s ease;
  background-color: #45515F;
}
