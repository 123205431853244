#event{
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(73, 88, 103, .65);
  height: 37px;
  transition: background ease 0.2s;
}

#event:first-child{
  border-top: 1px solid rgba(73, 88, 103, .65);
}

#event:hover{
  cursor: pointer;
  background-color: #45515F;
}

#event img{
  height: 26px;
  margin: 0 7px;
}

#event-data{
  line-height: 14px;
  color: #929a9e;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#event-data h4{
  font-size: 11px;
}



