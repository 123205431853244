*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

html,body, #root{
    min-height: 100%;
}

html{
    font-family: Open Sans,sans-serif;
    height: 100%;
    font-size: 12px;
    line-height: 1.66667;
}

body{
    background-color: black;
    background-image: url("https://static.hltv.org//images/retina2/csgofast/major2/2020-2.jpg");
    background-repeat: no-repeat;
    background-position: center 46px;
    color: #87a3bf;
}

a{
    text-decoration: none;
    color: inherit;
}
