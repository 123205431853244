#top-bar{
  z-index: 10000;
  position: fixed;
	display: flex;
	justify-content: center;
  height: 46px;
  width: 100%;
	background-color: #2D3844;
	align-items: center;
	vertical-align: middle;
	font-size: 14px;
	color: rgb(146, 154, 158);
	font-weight: 400;
  font-family: Open Sans,sans-serif;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.5);
}

#links{
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}

#links > img{
  height: 46px;
}

.top-bar-link{
	display: flex;
	align-items: center;
	height: 46px;
	border-right: 1px solid rgba(73, 88, 103, .65);
	transition: .2s ease;
}
.top-bar-link:first-child{
	border-left: 1px solid rgba(73, 88, 103, .65);
}

.top-bar-link a{
	font-weight: 400;
	font-size: 14px;
	color: rgb(146, 154, 158);
	padding: 0 11px;
}

.top-bar-link:hover{
	background-color: #45515F;
}

.top-bar-link a:hover{
	color: #7D96B1;
}

#search-box{
	max-width: 201px;
	padding: 0 16px;
}

#search-box-input{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #495867;
	border-radius: 2px;
}

#search-box-input:focus-within{
	border: 1px solid #87A3BF;
}

#search-icon{
	color: rgb(146, 154, 158);
}

#search-icon-box{
	padding: 0 5px;
  border-left: 1px solid #495867;
}

#search-box-input input{
	height: 25px;
	width: 100%;
	background-color: #2D3844;
	color: rgb(146, 154, 158);
	border: none;
	padding-left: 5px;
}

#search-box-input input::placeholder{
	color: #626b75;
	font-size: 14px;
	font-family: Open Sans,sans-serif;
}

#search-box-input input:focus{
	outline: none !important;
}

#user-actions{
	display:flex;
	align-items: center;
	font-weight: 800;
  font-size: 15px;
  color: #a2a2a2;
}

#user-bell-envelope{
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-left: 1px solid rgba(73, 88, 103, .65);
	border-right: 1px solid rgba(73, 88, 103, .65);
	padding:0 10px;
	color: #a2a2a2;
	cursor: pointer;
	height: 23px;
}

.user-icon{
	padding: 0 8px;
}

#user-caret{
	padding: 0 12px;
	cursor: pointer;
}
