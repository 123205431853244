.match{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 5px;
  border-bottom: 1px solid #495867;
  transition: background ease 0.2s;
  line-height: 1.56667;
}
.match:last-child{
  border: none;
}
.match-team{
  display: flex;
  align-items: center;
}
.match-teams{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.match:hover{
  cursor: pointer;
  background-color: #45515F;
}

.match img{
  width: 18px;
  height: 12px;
  border: 1px solid black;
  margin-right: 3px;
}

.match-team-points{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.green{
  color: #09b300;
}

.red{
  color: #c30404;
}

.match-round-points{
  margin-right: 3px;
}

.match-time{
  color: #929a9e;
}

.team-point{
  font-size: 11px;
}
