#player-of-the-week {
	position: relative;
	height: 150px;
	width: 160px;
	background-color: #2d3844;
	overflow: hidden;
	z-index: 2;
	margin-bottom: 8px;
	transition: background .3s ease;
	cursor: pointer;
}

#player-of-the-week:hover {
  background-color: #3f4955;
}

#player-of-the-week::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;
	height: 32px;
	background: linear-gradient(0deg, #3f4955,#2d384400);
}

#player-image-box{
	height: 186px;
}

#player-image {
	position: relative;
	max-height: 100%;
	bottom: -20px;
	left: -66px;
	z-index: -1;
}

#player-team-image-box {
	width: 100%;
	height: 100%;
}

#player-team-image {
	position: absolute;
	top: 50%;
	left: -16px;
  transform: translateY(-50%);
  opacity: 0.2 !important;
	width: 80px;
	height: 80px;
	z-index: -2;
	opacity: 0.6;
}

#player-data {
	position: absolute;
	top: 0;
	bottom: 12px;
	right: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: right;
}

#player-data strong {
  color: #fff;
  font-size: 20px;
}

#player-data span {
	color: #8AAED0;
}

#player-data > header {
	display: flex;
	flex-direction: column;
}

#player-data > footer {
	display: flex;
	flex-direction: column;
}
